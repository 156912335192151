import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ReplaySubject } from "rxjs";
import { filter, map } from "rxjs/operators";
import { UserPermissions } from "../../../features/auth/domain/models/user-permissions";

import { User } from "../../models/user";
import { HttpClient } from "../http-client";

@Injectable()
export class UserService {
  path = "user";
  private currentUser = new ReplaySubject<User>(1);

  constructor(private http: HttpClient) {}

  public signUp(
    email: string,
    password: string,
    signUpCampaignId?: number,
    brandActivationCode?: string,
    locale?: string,
    partnerId?: number,
  ): Observable<User> {
    return this.http
      .post(
        "sign-up",
        JSON.stringify({
          email: email,
          password: password,
          signUpCampaignId: signUpCampaignId,
          brandActivationCode: brandActivationCode,
          locale: locale,
          partnerId: partnerId,
        }),
      )
      .pipe(map(({ body }) => new User(body)));
  }

  addUserToCampaign(email: string, campaignSlug: string) {
    return this.http
      .post(
        "add-user-to-campaign",
        JSON.stringify({
          email: email,
          campaignSlug: campaignSlug,
        }),
      )
      .pipe(map((response) => response));
  }

  get(): Observable<User> {
    return this.http.get(this.path).pipe(
      map(({ body }) => {
        const user = new User(body);
        this.currentUser.next(user);
        return user;
      }),
    );
  }

  update(user: User): Observable<User> {
    return this.http
      .put(this.path, JSON.stringify(user))
      .pipe(map(({ body }) => new User(body)));
  }

  delete(): Observable<User> {
    return this.http.del(this.path).pipe(map(({ body }) => new User(body)));
  }

  recoverPassword(email: string): Observable<any> {
    return this.http
      .post(`password/request-reset`, JSON.stringify({ email: email }))
      .pipe(map((response) => response));
  }

  setNewPassword(
    email: string,
    password: string,
    token: string,
  ): Observable<any> {
    return this.http
      .put(
        `password/request-reset`,
        JSON.stringify({ email: email, password: password, token: token }),
      )
      .pipe(map((response) => response));
  }

  resendAccountConfirmationEmail(): Observable<any> {
    return this.http
      .post(`resend_account_confirmation_email`, {})
      .pipe(map((response) => response));
  }

  getBrandUsers(brandId: number): Observable<User[]> {
    return this.http
      .get(`brand/${brandId}/users`)
      .pipe(map(({ body }) => body.map((userData: any) => new User(userData))));
  }

  /* BRAND USER */
  createBrandUser(brandId: number, email: string): Observable<User> {
    return this.http
      .post(`brand/${brandId}/user`, JSON.stringify({ email: email }))
      .pipe(map(({ body }) => new User(body)));
  }

  inviteBrandUser(brandId: number, email: string): Observable<User> {
    return this.http
      .post(`brand/${brandId}/invite_user`, JSON.stringify({ email: email }))
      .pipe(map(({ body }) => new User(body)));
  }

  deleteBrandUser(brandId: number, userId: number): Observable<any> {
    return this.http
      .del(`brand/${brandId}/user/${userId}`)
      .pipe(map(({ body }) => body));
  }

  /* PARTNER USER */
  createPartnerUser(partnerId: number, email: string): Observable<User> {
    return this.http
      .post(`partner/${partnerId}/user`, JSON.stringify({ email: email }))
      .pipe(map(({ body }) => new User(body)));
  }

  invitePartnerUser(partnerId: number, data: any): Observable<User> {
    return this.http
      .post(`partner/${partnerId}/invite_user`, JSON.stringify(data))
      .pipe(map(({ body }) => new User(body)));
  }

  deletePartnerUser(partnerId: number, userId: number): Observable<any> {
    return this.http
      .del(`partner/${partnerId}/user/${userId}`)
      .pipe(map(({ body }) => body));
  }

  public getPermissions(): Observable<UserPermissions> {
    return this.currentUser.asObservable().pipe(
      filter((user) => !!user),
      map((user) => user.permissions),
    );
  }
}
